body {
	overflow-x: hidden;
}

.site-header-container {
	padding: 1rem 2rem;
	border-bottom: 1px solid #09415b;
	box-shadow: 0 0 10px #09415b;
	height: 82px;
	box-sizing: border-box;

	position: fixed;
	width: 100%;
	background-color: #fff;
	z-index: 9999;
}

.site-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
	max-width: 1366px;
}

.site-content {
	font-size: 2.1rem;
	margin: 0 auto;
	padding: 82px 0 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: calc(100vh - 103px);
}

.page-body {
	max-width: 1366px;
	width: 100%;
	margin: 0 auto;
	padding: 0 20px;
	position: relative;
}

h1 {
	font-size: 5rem;
	font-weight: 300;
}

h2 {
	font-size: 1.5em;
	font-weight: 300;
}

h3 {
	font-weight: 300;
	background: #ededed;
	width: 100%;
}

.step-content li h3 {
	background: none;
	font-weight: 600;
	margin-bottom: 0;
}

.step-content li p {
	margin: 0;
}

.step-content li p:nth-of-type(n + 2) {
	margin-top: 20px;
}

img {
	max-width: 100%;
}

a {
	color: #fa0;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

.site-footer {
	background: #09415b;
	color: #fff;
	padding: 40px 20px;
	font-size: 1.4rem;
	text-align: center;
	position: relative;
	z-index: 1;
	display: flex;
	justify-content: space-between;
}

@media screen and (max-width: 1139px) {
	.site-footer {
		flex-direction: column;
		align-items: center;
	}

	.copyright {
		order: 2;
		margin-top: 20px;
	}
}

@media screen and (max-width: 799px) {
	.site-header-container {
		padding: 0;
	}
}

.step-containers {
	padding: 0 20px;
}

.step-container {
	padding: 20px 0;
	text-align: left;
	position: relative;
}

.step-container:first-child {
	padding-top: 40px;
}

.step-container:last-child {
	padding-bottom: 40px;
}

.step-fullwidth {
	background: white;
	flex-direction: column;
	z-index: 2;
	position: relative;
	padding: 20px;
	border: 1px solid;
	margin: 40px auto 20px;
	text-align: center;
	width: 80%;
}

.step-fullwidth h2 {
	margin: 0;
}

.step-even {
	flex-direction: row-reverse;
}

.step-title {
	min-height: 2em;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	color: #fff;
	padding: 10px 20px;
	font-size: 6rem;
	width: 100vw;
	margin-left: calc(-50vw + 50%);
	flex-wrap: wrap;
	background: #0a577b;
	text-align: center;
    line-height: 1.1;
}

.step-title em {
	text-shadow: 5px 5px 4px #09415b;
}

@media screen and (max-width: 799px) {
	.step-fullwidth {
		width: 100%;
	}

	.step-title {
		font-size: 4rem;
		text-align: center;
	}

	.step-content {
		padding: 0 20px;
	}
}