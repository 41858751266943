.work-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 60px;
    width: 90vw;
    margin-left: calc(-45vw + 50%);
}

.work-section article {
    flex: 0 0 calc(50% - 10px);
    border: 1px solid #09415b;
    border-radius: 20px;
    overflow: hidden;
    min-height: 500px;
    position: relative;
}

@media (max-width: 899px) {
    .work-section article {
        flex-basis: 100%;
    }
}

@media (max-width: 799px) {
    .work-section article {
        min-height: 300px;
    }
}

.work-section img {
    width: 100%;
    height: 100%;
    
    max-width: unset;
    
    object-fit: cover;
    object-position: top left;

    position: relative;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.work-section p {
    margin: 0;
}

.work-section article h3 {
    position: absolute;
    bottom: 0;
    margin: 0;
    text-align: center;
    padding: 0 20px;
}